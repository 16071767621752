<template>
  <div class="conetnt">
    <div class="header_box">
      <div class="h_logo">
        <img src="../../../assets/images/logo.png"/>
      </div>
      <div class="erq">
        <img id="barcode"/>
      </div>
      <div style="clear: both"></div>
    </div>
    <div class="c_txt">2022年清华大学特殊类型招生考试准考证</div>
    <table cellspacing="0px">
      <tbody>
      <tr>
        <td width="25%">准考证号</td>
        <td width="25%">{{ tableData.zkz_num }}</td>
        <td width="25%">姓名</td>
        <td width="25%">{{ tableData.name }}</td>
      </tr>
      <tr>
        <td>考试名称</td>
        <td>2022年清华大学特殊类型招生考试</td>
        <td>性别</td>
        <td>
                <span v-if="tableData.gender == 1">女</span
                ><span v-else>男</span>
        </td>
      </tr>
      <tr>
        <td>身份证号</td>
        <td colspan="3">{{ tableData.sfz_num }}</td>
      </tr>
      <tr>
        <td :rowspan="tableData.rowspan">
          <img
            :src="
                    tableData.avatar
                      ? tableData.avatar
                      : 'https://spartaimages.oss-cn-beijing.aliyuncs.com/avatar/test.png'
                  "
          />
        </td>
        <td>考试日期</td>
        <td>考试科目</td>
        <td>考试时间</td>
      </tr>
      <tr v-for="(item, index) in tableData.subject" :key="index">
        <td>{{ item.stu_start_time }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.kk_begin_time }}-{{ item.jj_end_time }}</td>
      </tr>
      <tr>
        <td>考区</td>
        <td colspan="3">{{ tableData.province }}</td>
      </tr>
      <tr>
        <td>考点名称</td>
        <td colspan="3">{{ tableData.school_name }}</td>
      </tr>
      <tr>
        <td>考点地址</td>
        <td colspan="3">{{ tableData.school_address }}</td>
      </tr>
      <tr>
        <td>机房位置</td>
        <td colspan="3">{{ tableData.exam_room_address }}{{ tableData.exam_room_name }}</td>
      </tr>
      <tr>
        <td>考位编号</td>
        <td colspan="3">{{ tableData.pc_no }}</td>
      </tr>
      </tbody>
    </table>
    <div class="fy_text">
      <div class="fy_text_sign">防疫要求</div>
      <div class="fy_text_section" v-for="(item, index) in tableData.requirement" :key="index">{{ item }}</div>
    </div>
    <div class="fy_text" v-if="tableData.exam_name=='居家考试'">
      <div class="fy_text_sign"><span>*</span>重要提醒</div>
      <div class="fy_text_section">考生须按照《2022年清华大学特殊类型招生考试考生指南》的要求准备好考试及监控设备，并于4月16日上午9:00-12:00参加统一调试。如因未参加统一调试而导致无法正常进行考试的，相应后果由考生本人承担。</div>
      <div class="fy_text_section">本次网络远程考试指引文档共含2个文件：</div>
      <div class="fy_text_section">1.《2022年清华大学特殊类型招生考试考生指南》</div>
      <div class="fy_text_section">2.《2022年清华大学特殊类型招生考试考场规则》</div>
      <div class="fy_text_section">请考生及时下载，仔细阅读并按要求提前做好相关准备。</div>
    </div>
  </div>
</template>

<script>
  import {getZkzInfoBySfz} from "r/index/printAtc.js";
  import {formatTime, formatDate} from "s/time.js";

  export default {
    name: "printAtc",
    data() {
      return {
        sfz_num: "",
        tableData: {},
      };
    },
    created() {
      console.log(this.$route.params.sfz_num)
      this.sfz_num = this.$route.params.sfz_num;
      this.getZkzInfoBySfz();
    },
    mounted() {
      JsBarcode("#barcode", this.tableData.zkz_num, {
        format: "CODE39",
        lineColor: "#000",
        background: "#ffffff",
        width: 1,
        height: 20,
        displayValue: true,
      });
    },
    methods: {
      // 获取准考证信息
      async getZkzInfoBySfz() {
        // 发送请求的操作
        let {data: res} = await getZkzInfoBySfz({
          sfz_num: this.sfz_num
        });
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        let requirement = [];
        if(res.school_address.requirement){
          requirement = res.school_address.requirement.split("；");
        }
        this.tableData = {
          zkz_num: res.zkz_num,
          name: res.name,
          exam_name: res.exam_name,
          gender: res.gender,
          sfz_num: res.sfz_num,
          avatar: res.avatar,
          subject: res.subject,
          rowspan: res.subject.length + 1,
          city: res.city.name,
          province: res.province.name,
          school_name: res.school_address.name,
          school_address: res.school_address.address,
          exam_room_name: res.exam_room_name,
          exam_room_address: res.exam_room_address,
          pc_no: res.pc_no,
          requirement: requirement,
        };
        this.tableData.subject.sort((a, b) => {
          let value1 = a["kk_begin_time"];
          let value2 = b["kk_begin_time"];
          return value1 - value2;
        });
        this.tableData.subject.forEach((i) => {
          i.stu_start_time = formatDate(i.jj_end_time * 1000);
          i.jj_end_time = formatTime(i.jj_end_time * 1000);
          i.kk_begin_time = formatTime(i.kk_begin_time * 1000);
        });
        this.createQrCode();
      },

      // 生成一维码
      createQrCode() {
        JsBarcode("#barcode", this.tableData.zkz_num, {
          format: "CODE128",
          lineColor: "#000",
          background: "#ffffff",
          width: 1,
          height: 30,
          displayValue: true,
          fontSize: 12,
          margin: 0,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../assets/font/font.css';

  .conetnt {
    width: 694px;
    height: 1022px;
    margin: 0 auto;
    padding: 50px;
    background-color: #ffffff;

    .header_box {
      position: relative;

      .h_logo {
        float: left;

        img {
          width: 160px;
        }
      }

      .erq {
        float: right;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .c_txt {
      text-align: center;
      padding: 22px 0;
      font-size: 26px;
      font-weight: bold;
      font-family: '华文中宋';
    }

    .fy_text {
      /*position: relative;*/
      /*left: 10px;*/
      /*top: 10px;*/
      padding: 10px;

      .fy_text_sign {
        position: relative;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;

        > span {
          position: absolute;
          left: -8px;
          color: red;
        }
      }

      .fy_text_section {
        font-family: "仿宋";
        font-size: 14px;
        width: 640px;
        line-height: 24px;
      }
    }

    table {
      width: 100%;
      border: 2px solid #000000;

      tr {
        width: 100%;

        td {
          font-family: "仿宋";
          color: #333333;
          font-size: 14px;
          text-align: center;
          border-right: 1px solid #666666;
          border-bottom: 1px solid #666666;
          height: 40px;

          &:last-child {
            border-right: 0;
          }

          img {
            // width: 100%;
            height: 200px;
            border: 0;
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }

  ::v-deep .vue-barcode-element {
    width: 200px;
    height: 60px;

    rect {
      height: 60px;
    }
  }
</style>
